import Img1 from "../../images/Resume/2025-02/Headshots_1.jpg";
import Img2 from "../../images/Resume/2025-02/Headshots_2.jpg";
import Img3 from "../../images/Resume/2025-02/Headshots_3.jpg";
import Img4 from "../../images/Resume/2025-02/Headshots_4.jpg";
import Img5 from "../../images/Resume/2025-02/Headshots_5.jpg";
import Img6 from "../../images/Resume/2025-02/Headshots_6.jpg";
import Img7 from "../../images/Resume/2025-02/Headshots_7.jpg";
import Img8 from "../../images/Resume/2025-02/Headshots_8.jpg";
import Img9 from "../../images/Resume/2025-02/Headshots_9.jpg";
import Img10 from "../../images/Resume/2025-02/Headshots_10.jpg";
import Img11 from "../../images/Resume/2025-02/Headshots_11.jpg";
import Img12 from "../../images/Resume/2025-02/Headshots_12.jpg";
import Img13 from "../../images/Resume/2025-02/Headshots_13.jpg";
import Img14 from "../../images/Resume/2025-02/Headshots_14.jpg";
import Img15 from "../../images/Resume/2025-02/Headshots_15.jpg";
import Img16 from "../../images/Resume/2025-02/Headshots_16.jpg";


export const headshots = [
  { src: Img1, width: 1, height: 1.5, caption: "Photo by Kenneth Billington" },
  { src: Img2, width: 1, height: 1.5, caption: "Photo by Kenneth Billington" },
  { src: Img6, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img4, width: 1, height: 1.5, caption: "Photo by Kenneth Billington" },
  { src: Img3, width: 1, height: 1.5, caption: "Photo by Kenneth Billington" },
  { src: Img5, width: 1, height: 1.5, caption: "Photo by Kenneth Billington" },
  { src: Img7, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img8, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img9, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img10, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img11, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img12, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img15, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img13, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img16, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
  { src: Img14, width: 2, height: 3, caption: "Photo by Kenneth Billington" },
];