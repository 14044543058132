// @flow

import React, { Component } from 'react';
import './home.scss';
import LizHome from '../../images/Resume/2025-02/FRONT-PAGE.jpg';


export default class Home extends Component {
	render() {
		return (
      <div className="wrapper-home">
        <div className="home-imgDiv">
          <img className="home-img" src={LizHome} alt="" />
        </div>
        <div className="rightCol">
          <div className="speedReel">
            {/* <iframe className="reelIframe" src="https://player.vimeo.com/video/415444879?color=ffffff&byline=0&portrait=0" title="Speed Reel" width="900" allow="autoplay; fullscreen"></iframe> */}
            <iframe
              className="reelIframe"
              loading="lazy"
              src="https://player.vimeo.com/video/517397117?color=ffffff&byline=0&portrait=0"
              title="Speed Reel"
              width="900"
              frameborder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
          <div className="homeDesc">
            <p>
              Welcome friends! I’m Yelizaveta. I’m an actor, writer, and editor
              based in Los Angeles. Also part time adrenaline junkie. Don’t tell
              my mom.
            </p>
            <p>
              Here’s a little taste of what I do. Thanks for stopping by, and
              let me know if I can get you anything!
            </p>
          </div>
        </div>
      </div>
    );
	}
}
